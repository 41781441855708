<template>
    <div class="main-section pa-5">
        <div class="hb-font-header-3-medium mb-4">Direct Refund</div>
        <div class="hb-font-body-medium mb-3">Account Information</div>
        <!-- <p class="hb-font-body">Return Total: $30.00</p> -->
            <v-row class="mx-0">
                <v-col class="pt-1 pl-0">
                    <v-text-field
                        label="Customer First Name*"
                        class="pt-2"
                        hide-details
                        v-model="returnData.firstName"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="firstName"
                        data-vv-as="First Name"
                        :error="errors.has('default.firstName')"
                    ></v-text-field>
                </v-col>
                <v-col class="pt-1 px-0">
                <v-text-field
                        label="Customer Last Name*"
                        v-model="returnData.lastName"
                        class="pt-2"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="lastName"
                        data-vv-as="Last Name"
                        :error="errors.has('default.lastName')"
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row class="mx-0">
                <v-col class="pt-1 pl-0">
                    <v-text-field
                        label="Phone Number"
                        v-model="returnData.phone"
                        class="pt-2"
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col class="pt-1 px-0">
                <v-text-field
                        label="Email"
                        v-model="returnData.email"
                        class="pt-2"
                        v-validate="'email'"
                        data-vv-scope="default"
                        data-vv-name="email"
                        data-vv-as="email"
                        :error="errors.has('default.email')"
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-col class="pt-1 px-0">
            <v-text-field
                label="Account Name*"
                v-model="returnData.account_name"
                class="pt-2"
                v-validate="'required'"
                data-vv-scope="default"
                data-vv-name="accountName"
                data-vv-as="Account Name"
                :error="errors.has('default.accountName')"
                hide-details
            ></v-text-field>
        </v-col>

        <v-col class="pt-1 px-0">
            <v-text-field
                label="BSB Number*"
                v-model="returnData.bsb_number"
                class="pt-2"
                v-validate="'required'"
                data-vv-scope="default"
                data-vv-name="bsbNumber"
                data-vv-as="BSB Number"
                :error="errors.has('default.bsbNumber')"
                hide-details
            ></v-text-field>
        </v-col>

        <v-col class="pt-1 px-0">
            <v-text-field
                label="Account Number*"
                v-model="returnData.account_number"
                class="pt-2"
                v-validate="'required'"
                data-vv-scope="default"
                data-vv-name="accountNumber"
                data-vv-as="Account Number"
                :error="errors.has('default.accountNumber')"
                hide-details
            ></v-text-field>
        </v-col>
    </div>
</template>

<script>
export default {
    name: "ReturnDirectRefund",
    data () {
        return {
        }
    },
    props: {
        returnData: {
            type: Object,
            default: {}
        }
    },
}
</script>